import { UsuarioModel } from './usuarioModel';
import { BaseModel } from './baseModel';
import { AgenciaTurismoModel } from './agenciaTurismoModel';

export class GuiaTurismoModel extends BaseModel {
    usuario: UsuarioModel;
    agenciaTurismo: AgenciaTurismoModel;
    flGuiaAprovadoAgencia: boolean;
    flGuiaAdministrador: boolean;
    dlConfirmacaoSenha: string;    // usado na criação de guia/usuario
    
    constructor() {
        super();
        this.usuario = new UsuarioModel();
        this.agenciaTurismo = new AgenciaTurismoModel();
    }

}